import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Input, TextArea, DateInput } from '../../components/Input'
import { Button } from '../../components/Button'
import { Campaign } from '../../api/types'
import { useApi } from '../../api'
import { Loader } from '../../components/Loader'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
    campaign: Campaign
}

export function EventSubmissionForm({ campaign }: Props): JSX.Element {
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const navigate = useNavigate()

    const api = useApi()
    const { mutate: createEvent, isLoading } = api.useCreateEvent({
        onSuccess: (data) => navigate(`/event/${data._id}`),
    })

    const onSubmit = async (data: any) => createEvent({ ...data, campaign: campaign._id })

    useEffect(() => {})

    return (
        <div className="space-y-8 max-w-xl">
            <h2 className="text-2xl font-medium">Submit An Event</h2>
            {isLoading ? (
                <Loader />
            ) : (
                <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-4">
                        <Input
                            error={errors.name && 'An event name is required.'}
                            label="Name"
                            description="A short but descriptive name for your event."
                            placeholder="Give your event a name"
                            {...register('name', { required: true })}
                        />
                        <TextArea
                            error={errors.description && 'An event description is required.'}
                            label="Description"
                            placeholder="Give your event a description"
                            description="Try to be as descriptive as possible so the host organisation can understand what your event is about."
                            {...register('description', { required: true })}
                        />
                        <Input
                            error={errors.duration && 'A duration is required.'}
                            label="Duration"
                            type="number"
                            description="How many hours do you plan your event to be?"
                            placeholder={`Maximum of ${campaign.rules.maxDuration} Hours`}
                            {...register('duration', {
                                required: true,
                                valueAsNumber: true,
                                min: 1,
                                max: campaign.rules.maxDuration,
                            })}
                        />
                        <Input
                            error={errors.duration && 'A capacity is required.'}
                            label="Capacity"
                            type="number"
                            description="What's the maximum number volunteers you expect?"
                            placeholder={`Maximum of ${campaign.rules.maxCapacity} Volunteers`}
                            {...register('capacity', {
                                required: true,
                                valueAsNumber: true,
                                min: 1,
                                max: campaign.rules.maxCapacity,
                            })}
                        />
                        <Controller
                            control={control}
                            name="date"
                            render={({ field }) => (
                                <DateInput
                                    placeholderText="Click here to open the calendar"
                                    label="Date & Time"
                                    showTimeSelect={true}
                                    minDate={new Date()}
                                    selected={field.value}
                                    onChange={(date) => field.onChange(date)}
                                />
                            )}
                        />
                        {/* <DateInput
                        error={errors.date && 'A date and time is required.'}
                        label="Date & Time"
                        description="What date and time will your event be held?"
                        placeholder="Click here to open the calendar"
                        {...register('date', { required: true })}
                    /> */}
                        <Button type="submit">Submit Event</Button>
                    </div>
                </form>
            )}
            {/* <div className="w-96">
                    <div className={''} htmlFor={id}>
                        Event Image
                    </div>
                    <div className="bg-gray-200 rounded-lg pb-[100%]"></div>
                </div> */}
        </div>
    )
}
