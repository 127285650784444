import React from 'react'
import { Link } from 'react-router-dom'
import { useApi } from '../../api'
import { Loader } from '../../components/Loader'
import { Status } from '../../components/Status'

export function Events(): JSX.Element {
    const api = useApi()

    const { data: events, isFetching } = api.useGetEvents()

    return (
        <div className="space-y-8">
            <div className="relative">
                <h1 className="text-4xl mt-2">Your Events</h1>
            </div>
            {isFetching || !events ? (
                <Loader />
            ) : (
                <div className="grid grid-cols-1 gap-8">
                    {events.map((event) => (
                        <Link key={event._id} to={`/event/${event._id}`}>
                            <div className="bg-white shadow-md rounded-3xl overflow-hidden">
                                <div className="p-4 space-y-1">
                                    <Status small status={event.status} />
                                    <h2 className="text-xl font-bold">{event.name}</h2>
                                    <p className="font-medium">{event.campaign.name}</p>
                                    <p className="text-gray-500">
                                        {new Intl.DateTimeFormat('en-GB', {
                                            dateStyle: 'full',
                                            timeStyle: 'short',
                                        }).format(new Date(event.date))}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    )
}
