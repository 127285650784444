import { UseMutationOptions } from 'react-query'

export type Campaign = {
    _id: string
    name: string
    organisation: string
    description: string
    createdAt: string
    updatedAt: string
    rules: {
        maxCapacity: number
        maxDuration: number
    }
}
export type Organisation = {
    _id: string
    name: string
    createdAt: string
    updatedAt: string
}

export type CreateEventPayload = {
    campaign: string
    name: string
    description: string
    capacity: number
    duration: number
    date: Date
}

export enum Currency {
    ZAR = 'ZAR',
    CELO = 'CELO',
}

export enum Wallet {
    // Valora = 'valora',
    Celo = 'celo',
    MoyaPayD = 'moyapayd',
}

export type Volunteer = {
    _id: string
    name: string
}

export type Event = {
    _id: string
    name: string
    description: string
    campaign: string
    capacity: number
    champion: string
    createdAt: string
    date: string
    duration: number
    funding: {
        currency: Currency
        reward: string
        total: string
    }
    organisation: string
    status: string
    updatedAt: string
    volunteers: Record<string, Volunteer>
}

export type Error = {
    error: string
    message: string
    statusCode: number
}

export type User = {
    _id: string
    firstName: string
    lastName: string
    updatedAt: string
    createdAt: string
    wallets: {
        celo?: {
            account: string
        }
    }
}

export type EventWithCampaign = Event & {
    campaign: Campaign
}
export type CampaignWithOrganisation = Campaign & {
    organisation: Organisation
}
export type MutationOptions<T> = Omit<UseMutationOptions<unknown, unknown, T, unknown>, 'mutationFn'>
