import { useMutation, useQuery } from 'react-query'
import { AxiosInstance } from 'axios'
import { User, Wallet } from './types'

type SignupPayload = {
    firstName: string
    lastName: string
    birthDate: string
    gender: string
    picture: string
    wallet?: Wallet
    account?: string
}

type UserByWalletPayload = {
    wallet: string
    id: string
}

export default (axios: AxiosInstance) => ({
    useSignup: (options = {}) =>
        useMutation<User, unknown, SignupPayload, unknown>(
            (data) => axios.post(`/user`, data).then(({ data }) => data),
            options
        ),
    useSignupVolunteer: (options = {}) =>
        useMutation<User, unknown, SignupPayload, unknown>(
            (data) => axios.post(`/user/volunteer`, data).then(({ data }) => data),
            options
        ),
    useGetCurrentUser: ({ key = ['user'], ...options }: any = {}) =>
        useQuery<any, any, User, any>(key, () => axios.get(`/user`).then(({ data }: any) => data), {
            staleTime: Infinity,
            refetchOnMount: true,
            ...options,
        }),
    useGetUserById: (id: string, { key = ['user', id], ...options } = {}) =>
        useQuery<any, any, User, any>(key, () => axios.get(`/user/${id}`).then(({ data }: any) => data), {
            staleTime: Infinity,
            refetchOnMount: true,
            ...options,
        }),
    useGetUserByWallet: (options = {}) =>
        useMutation<unknown, unknown, UserByWalletPayload, unknown>(
            ({ wallet, id }) => axios.get(`/user/${wallet}/${id}?ts=${Date.now()}`).then(({ data }) => data),
            options
        ),
})
