import React from 'react'
import ReactDOM from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter } from 'react-router-dom'
import { auth0 } from './config'

import reportWebVitals from './reportWebVitals'

import './index.css'
import { App } from './App'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    // <React.StrictMode>
    <Auth0Provider
        domain={auth0.domain}
        clientId={auth0.clientId}
        authorizationParams={{
            redirect_uri: window.location.origin,
            audience: auth0.audience,
        }}
    >
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <div className="w-full min-h-screen bg-slate-50">
                    <App />
                </div>
            </BrowserRouter>
            {/* <RouterProvider router={router} /> */}
        </QueryClientProvider>
    </Auth0Provider>
    // </React.StrictMode>
)
