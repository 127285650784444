const isLocal = process.env.DEV

export const auth0 = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? '',
}

export const urls = {
    media: process.env.REACT_APP_MEDIA_URL,
    api: isLocal ? 'http://localhost:3001' : process.env.REACT_APP_API_URL ?? '',
}
