import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useApi } from './api'
import { Header } from './components/Header'
import { AppContext, AppState, appStateKey } from './App.context'
import { Explore } from './pages/Explore'
import { Event } from './pages/event/Event'
import { Campaign } from './pages/campaign/Campaign'
import { Events } from './pages/event/Events'
import { Signout } from './pages/Signout'
import { Signup } from './pages/Signup'
import { User } from './api/types'

export function App(): JSX.Element {
    const navigate = useNavigate()
    const api = useApi()
    const {
        isLoading,
        error,
        loginWithRedirect,
        // user,
        logout,
        isAuthenticated,
    } = useAuth0()

    const [appState, setAppState] = useState<AppState>(
        useCallback(() => {
            let appState: AppState = {}
            let cache = localStorage.getItem(appStateKey)
            try {
                if (cache) appState = JSON.parse(cache)
                else appState = {}
            } catch (error) {
                console.error(error)
            }

            return {
                ...appState,
                // Always false on load
                requireSignup: false,
                userLoaded: !!appState.user,
            }
        }, [])
    )

    api.useGetCurrentUser({
        enabled: isAuthenticated && appState.requireSignup !== true,
        retry: (_count: number, response: any) => {
            if (response.statusCode === 404) return false
            else return true
        },
        onSettled: () => {
            setAppState((state) => ({ ...state, userLoaded: true }))
        },
        onSuccess: (user: User) => {
            setAppState((state) => ({ ...state, user }))
        },
        onError: (error: any) => {
            if (error.statusCode === 404) setAppState((state) => ({ ...state, requireSignup: true, user: undefined }))
            else navigate('/signout')
        },
    })

    useEffect(() => {
        console.log(appState)
        localStorage.setItem(appStateKey, JSON.stringify(appState))
        // if (!appState.organisation) navigate('/select')
    }, [appState])

    // useEffect(() => {
    //     setAppState((state) => ({ ...state, user }))
    // }, [user])

    if (error) {
        console.log('Error', error)
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        })

        return <></>
    }

    if (!isAuthenticated && !isLoading) {
        console.log('Not authenticated, going to login')
        loginWithRedirect()
        return <></>
    }

    if (isLoading || !appState.userLoaded) return <div>Loading...</div>

    const providerState = {
        appState,
        setAppState,
    }

    return (
        <>
            <div className="h-full flex flex-col">
                <AppContext.Provider value={providerState}>
                    {!!appState.user && <Header />}
                    <div className="flex flex-auto justify-center">
                        <div className="w-full py-10 md:py-20 max-w-4xl px-4 sm:px-6 lg:px-8">
                            {!appState.user ? (
                                <Routes>
                                    <Route
                                        index
                                        element={
                                            <Signup
                                                onUser={(user) => {
                                                    setAppState((state) => ({ ...state, requireSignup: false, user }))
                                                    navigate('/')
                                                }}
                                            />
                                        }
                                    />
                                </Routes>
                            ) : (
                                <Routes>
                                    <Route path="campaign/:id/*" element={<Campaign />} />
                                    <Route path="event/:id/*" element={<Event />} />
                                    <Route path="events" element={<Events />} />
                                    <Route path="signout" element={<Signout />} />
                                    <Route index element={<Explore />} />
                                </Routes>
                            )}
                        </div>
                    </div>
                </AppContext.Provider>
            </div>
        </>
    )
}
