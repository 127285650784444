import React from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { useApi } from '../../api'
import { Loader } from '../../components/Loader'
import { Button } from '../../components/Button'
import { EventSubmissionForm } from './EventSubmissionForm'
import { OrgChip } from '../../components/OrgChip'
import { urls } from '../../config'

export function Campaign(): JSX.Element {
    let { id } = useParams()
    const api = useApi()
    const navigate = useNavigate()

    const { data: campaign, isFetching, isError } = api.useGetCampaign(id ?? '')
    if (isError) navigate('/')

    return (
        <div className="space-y-8">
            {isFetching || !campaign ? (
                <Loader />
            ) : (
                <>
                    <div
                        className="w-full relative h-96 bg-cover bg-center bg-gray-200 rounded-3xl shadow-md"
                        style={{
                            backgroundImage: `url(${urls.media}/campaign/${campaign._id}.jpg?ts=${new Date(
                                campaign.organisation.updatedAt
                            ).valueOf()})`,
                        }}
                    >
                        <div className="absolute p-4 bottom-0 ">
                            <OrgChip organisation={campaign.organisation} />
                        </div>
                    </div>
                    <div className="space-y-2">
                        <h1 className="text-4xl mt-2">{campaign.name}</h1>
                        <div className="flex space-x-8 flex-wrap">
                            <div className="text-gray-500 flex space-x-2">
                                <div className="font-medium">Max Capacity:</div>
                                <div>{campaign.rules.maxCapacity} Volunteers</div>
                            </div>
                            <div className="text-gray-500 flex space-x-2">
                                <div className="font-medium">Max Duration:</div>
                                <div>{campaign.rules.maxCapacity} Hours</div>
                            </div>
                        </div>
                    </div>

                    <div className="border-l-4 max-w-xl pl-4 py-2 border-teal-400">{campaign.description}</div>
                    <Routes>
                        <Route
                            path="submit"
                            element={
                                <>
                                    <hr />
                                    <EventSubmissionForm campaign={campaign} />
                                </>
                            }
                        />
                        <Route
                            index
                            element={
                                <div className="max-w-xl">
                                    <Button to="submit">Submit An Event</Button>
                                </div>
                            }
                        />
                    </Routes>
                </>
            )}
        </div>
    )
}
