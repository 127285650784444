import React from 'react'
import { User } from '../api/types'
import { useRef, useState } from 'react'
import { Button } from '../components/Button'
import { Input, Select, DateInput } from '../components/Input'
import { Controller, useForm } from 'react-hook-form'
import { Camera, Settings } from '../components/Camera'
import ms from 'ms'
import { CameraIcon } from '@heroicons/react/24/solid'
import { useApi } from '../api'
import { Loader } from '../components/Loader'
import logo from '../assets/logo.svg'
type Props = {
    onUser: (user: User) => void
}

export function Signup({ onUser }: Props): JSX.Element {
    const [data, setData] = useState<any | null>(null)
    const cameraRef = useRef<Settings | null>(null)
    const api = useApi()
    const { mutateAsync: signup, isLoading } = api.useSignup()

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm()

    function submitForm(data: any) {
        signup({
            ...data,
            birthDate: data.birthDate.toISOString().split('T')[0],
        }).then((user) => onUser(user))
    }

    function onSubmitImage(picture: string) {
        submitForm({ ...data, picture: picture.replace('data:image/jpeg;base64,', '') })
    }

    if (isLoading) return <Loader />

    return (
        <div className="w-full max-w-md m-auto space-y-8">
            <div className="space-y-4 text-center">
                <img src={logo} className="h-24 m-auto" />
                {/* <div className="text-xs text-gray-500">
                    It's this volunteer's first time checking in with this wallet.
                </div> */}
                <h1 className="text-2xl">Welcome To Vollar</h1>
            </div>
            <div className="bg-white p-8 space-y-8 rounded-2xl shadow-2xl">
                {!data ? (
                    <>
                        <div className="text-lg max-w-xs m-auto">
                            Before you get started we need to collected some basic information.
                        </div>
                        <form className="w-full" onSubmit={handleSubmit(setData)}>
                            <div className="space-y-4">
                                <Input
                                    error={errors.firstName && 'Please write the volunteers last name.'}
                                    label="First Name"
                                    placeholder="First Name"
                                    {...register('firstName', {
                                        required: true,
                                    })}
                                />
                                <Input
                                    error={errors.firstName && 'Please write the volunteers first name.'}
                                    label="Last Name"
                                    placeholder="Last Name"
                                    {...register('lastName', {
                                        required: true,
                                    })}
                                />
                                <Select
                                    error={errors.gender && 'Please select the volunteers gender.'}
                                    label="Gender"
                                    {...register('gender', {
                                        required: true,
                                    })}
                                >
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </Select>
                                <Controller
                                    control={control}
                                    name="birthDate"
                                    render={({ field }) => (
                                        <DateInput
                                            placeholderText="Click here to open the calendar"
                                            label="Birth Date"
                                            maxDate={new Date(Date.now() - ms('13 years'))}
                                            // scrollableYearDropdown={true}
                                            // showYearDropdown={true}
                                            // yearDropdownItemNumber={100}
                                            // peekNextMonth
                                            dateFormat="dd MMMM yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            selected={field.value}
                                            withPortal
                                            onChange={(date) => field.onChange(date)}
                                        />
                                    )}
                                />
                                <Button type="submit">Continue</Button>
                            </div>
                        </form>
                    </>
                ) : (
                    <>
                        <div className="text-lg max-w-xs m-auto">
                            Finally, we need to take a picture for security purposes.
                        </div>
                        <div className="aspect-square rounded-3xl overflow-hidden">
                            <Camera
                                onError={() => submitForm(data)}
                                onStart={(settings) => (cameraRef.current = settings)}
                            />
                        </div>
                        <Button
                            onClick={() => {
                                onSubmitImage(
                                    cameraRef.current?.capture({
                                        width: 500,
                                        height: 500,
                                        type: 'jpeg',
                                    }) as string
                                )
                            }}
                            type="submit"
                            className="space-x-2"
                        >
                            <CameraIcon className="h-6" /> <div>Take Picture</div>
                        </Button>
                    </>
                )}
            </div>
        </div>
    )
}
