import { QrCodeIcon } from '@heroicons/react/24/outline'
import { Camera, Settings } from './Camera'
import { useEffect, useState } from 'react'

import { scanImageData } from 'zbar.wasm'
import React from 'react'

type Props = {
    title?: string
    onScan: (data: string) => void
}

// function isSupported() {
//     if (typeof window.WebAssembly !== 'object' || typeof window.Worker !== 'function') {
//         return false
//     }

//     return true
// }

export function QrCamera({ title, onScan }: Props) {
    const [settings, setSettings] = useState<Settings | null>(null)
    useEffect(() => {
        if (settings) {
            // const worker = new Worker(new URL('./QrCamera.worker.ts', import.meta.url))
            // const worker = new Worker(qrCameraWorkerUrl, {
            //     type: 'module',
            // })
            // worker.onmessage = (event) => {
            //     if (event.data) {
            //         onScan(event.data)
            //     }
            // }
            const interval = setInterval(() => {
                const imageData = settings.capture({
                    width: 250,
                    height: 250,
                    type: 'data',
                }) as ImageData

                // worker.postMessage(imageData, [imageData.data.buffer])
                scanImageData(imageData)
                    .then((res) => {
                        console.log('Scan Result', res)
                        if (res.length) {
                            onScan(res[0].decode())
                        }
                    })
                    .catch((err) => {
                        console.log('Scan Error', err)
                    })
            }, 1000)

            return () => {
                clearInterval(interval)
                // worker.terminate()
            }
        }
    }, [settings])
    return (
        <div className="w-full h-full relative">
            <div className="absolute z-10 inset-0 overflow-hidden">
                {!!settings && (
                    <div
                        onClick={() => {
                            console.log(
                                settings.capture({
                                    width: 250,
                                    height: 250,
                                })
                            )
                        }}
                        className="absolute p-4 flex justify-center w-full items-center space-x-2 text-white z-10"
                    >
                        <QrCodeIcon className="h-8" /> <div>{title || 'Scan A QR Code'}</div>
                    </div>
                )}
                <div className="border-[4rem] border-black/50 rounded-3xl inset-0 absolute"></div>
            </div>
            <Camera onStart={setSettings} />
        </div>
    )
}
