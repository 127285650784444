import { useQuery } from 'react-query'
import { AxiosInstance } from 'axios'
import { Campaign, CampaignWithOrganisation, Organisation } from './types'

export default (axios: AxiosInstance) => ({
    useGetCampaignsForChampion: ({ key = ['campaign', 'explore'], ...options } = {}) =>
        useQuery<any, any, CampaignWithOrganisation[], any>(
            key,
            () => axios.get(`/campaign/champion`).then(({ data }: any) => data),
            {
                staleTime: Infinity,
                refetchOnMount: true,
                ...options,
            }
        ),

    useGetCampaign: (id: string, { key = ['campaign', id], ...options } = {}) =>
        useQuery<
            any,
            any,
            Campaign & {
                organisation: Organisation
            },
            any
        >(key, () => axios.get(`/campaign/${id}`).then(({ data }: any) => data), {
            staleTime: Infinity,
            refetchOnMount: true,
            ...options,
        }),
})
