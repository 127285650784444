import { Dialog } from '@headlessui/react'
import React from 'react'

type Props = {
    open: boolean
    onClose: () => void
    children?: React.ReactNode
}
export const Modal = ({ children, onClose, open }: Props) => (
    <Dialog open={open} onClose={onClose} className="relative z-50">
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-white/30 backdrop-blur-md" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
            {/* The actual dialog panel  */}
            <Dialog.Panel className="mx-auto space-y-6 max-w-md w-full p-6 shadow-2xl rounded-3xl bg-white">
                {children}
            </Dialog.Panel>
        </div>
    </Dialog>
)
