import React from 'react'
import { Dialog } from '@headlessui/react'
import { Currency, Event, Wallet } from '../../../api/types'
import celoLogo from '../../../assets/wallets/celo.png'
import celoIcon from '../../../assets/wallets/celo_icon.png'
import moyapayLogo from '../../../assets/wallets/moyapayd.png'
import moyapayIcon from '../../../assets/wallets/moyapayd_icon.png'

type Props = {
    onWallet: (wallet: Wallet) => void
    event: Event
}

const wallets: Record<
    Currency,
    Array<{
        id: Wallet
        name: string
        logo: string
        icon: string
    }>
> = {
    CELO: [{ id: Wallet.Celo, name: 'Celo', logo: celoLogo, icon: celoIcon }],
    ZAR: [{ id: Wallet.MoyaPayD, name: 'MoyaPayD', logo: moyapayLogo, icon: moyapayIcon }],
}

export function SelectWallet({ onWallet, event }: Props): JSX.Element {
    return (
        <>
            <div>
                <Dialog.Title className="text-2xl">Check-In</Dialog.Title>
                <div className="text-xs text-gray-500">Check-In volunteers with a supported wallets.</div>
            </div>
            <div>
                {Object.values(wallets[event.funding.currency]).map((wallet) => (
                    <div
                        key={wallet.id}
                        onClick={() => onWallet(wallet.id)}
                        className="flex cursor-pointer items-center rounded-xl space-x-4 p-2 hover:bg-teal-200"
                    >
                        <img alt="" src={wallet.icon} className="h-10 rounded-xl" />
                        <img alt="" src={wallet.logo} className="h-6" />
                    </div>
                ))}
            </div>
        </>
    )
}
