import React from 'react'
import clsx from 'clsx'

function getStatus(status: string) {
    switch (status) {
        case 'pending':
            return 'Pending'
        case 'approved':
            return 'Approved'
        case 'rejected':
            return 'Rejected'
        case 'cancelled':
            return 'Cancelled'
        case 'open':
            return 'Open'
        case 'closed':
            return 'Closed'
        default:
            return 'Unknown'
    }
}

type Props = {
    status: string
    small?: boolean
}
export const Status = ({ status, small }: Props) => (
    <div
        className={clsx(
            'inline-block rounded-full text-sm font-medium',
            {
                'bg-yellow-200 text-yellow-600': status === 'pending',
                'bg-green-200 text-green-600': status === 'approved',
                'bg-blue-200 text-blue-600': status === 'open',
                'bg-red-200 text-red-600': status === 'rejected' || status === 'cancelled',
                'bg-gray-600 text-gray-200': status === 'closed',
            },
            {
                'text-xs px-2 py-1': small,
                'px-4 py-2': !small,
            }
        )}
    >
        {getStatus(status)}
    </div>
)
