import React from 'react'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react'

export type CaptureImageOptions = {
    width?: number
    height?: number
    type?: 'jpeg' | 'png' | 'data'
    quality?: number
}
export type Settings = {
    capture: (options?: CaptureImageOptions) => string | ImageData
}
type Props = {
    className?: string
    // onError?: () => void
    onStart?: (settings: Settings) => void
    onError?: () => void
}

export function Camera({ onStart = () => {}, onError = () => {} }: Props) {
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const [error, setError] = useState<boolean>(false)
    const streamRef = useRef<MediaStream | null>(null)
    const [isActive, setIsActive] = useState<boolean>(false)
    const [facingMode, setFacingMode] = useState<{
        mode: 'user' | 'environment'
        force: boolean
        mirror?: boolean
    }>({
        mode: 'environment',
        force: true,
        mirror: false,
    })

    useEffect(() => {
        setIsActive(false)
        startCamera()

        return () => {
            if (streamRef.current) streamRef.current.getTracks().forEach((track) => track.stop())
            setIsActive(false)
        }
    }, [facingMode])

    useEffect(() => {
        if (error) onError()
    }, [error])

    function swapFacingMode(forceFacingMode: boolean = true) {
        const mode = facingMode.mode === 'user' ? 'environment' : 'user'
        setFacingMode({
            mode,
            force: forceFacingMode,
            mirror: mode === 'user',
        })
    }

    function startCamera() {
        if (navigator.mediaDevices) {
            try {
                window.navigator.mediaDevices
                    .getUserMedia({
                        video: {
                            facingMode: {
                                exact: facingMode.force ? facingMode.mode : undefined,
                                ideal: facingMode.mode,
                            },
                        },
                    })
                    .then((stream) => {
                        if (!videoRef.current) return
                        videoRef.current.srcObject = stream
                        streamRef.current = stream
                        onStart({
                            capture: (options = {}) => {
                                if (!videoRef.current) return ''
                                const video = videoRef.current
                                const canvas = document.createElement('canvas') as HTMLCanvasElement
                                canvas.width = options.width ?? video.videoWidth
                                canvas.height = options.height ?? video.videoHeight

                                const context = canvas.getContext('2d') as CanvasRenderingContext2D
                                context.imageSmoothingEnabled = true
                                context.imageSmoothingQuality = 'high'
                                const sourceWidth = video.videoWidth
                                const sourceHeight = video.videoHeight
                                const destWidth = canvas.width
                                const destHeight = canvas.height

                                // Scale image
                                const scale = Math.max(destWidth / sourceWidth, destHeight / sourceHeight)
                                const xOffset = destWidth / 2 - (sourceWidth / 2) * scale
                                const yOffset = destHeight / 2 - (sourceHeight / 2) * scale

                                if (facingMode.mirror) {
                                    context.translate(destWidth, 0)
                                    context.scale(-1, 1)
                                }

                                context.drawImage(video, xOffset, yOffset, sourceWidth * scale, sourceHeight * scale)

                                if (facingMode.mirror) {
                                    context.translate(destWidth, 0)
                                    context.scale(-1, 1)
                                }
                                if (options.type === 'data') return context.getImageData(0, 0, destWidth, destHeight)
                                return canvas.toDataURL(`image/${options.type || 'jpeg'}`, options.quality || 0.75)
                            },
                        })

                        setTimeout(() => {
                            setIsActive(true)
                        }, 1000)
                    })
                    .catch((error) => {
                        console.error(error)
                        if (error.constraint === 'facingMode') {
                            swapFacingMode(false)
                        } else {
                            setError(true)
                            // onError()
                        }
                    })
            } catch (error) {
                console.error(error)
                setError(true)
                // onError()
            }
        } else {
            console.error('No media devices')
            setError(true)
            // onError()
        }
    }

    return (
        <div className="w-full h-full relative bg-gray-800">
            {error ? (
                <div className="shadow-inner w-full p-8 h-full flex justify-center items-center">
                    <div className="text-white text-center">
                        <div className="text-xl">Failed to start the camera.</div>
                        <div className="text-sm">Check that your browser has permission to access the camera.</div>
                    </div>
                </div>
            ) : (
                <>
                    <video
                        autoPlay
                        className="w-full h-full object-cover"
                        style={{ transform: facingMode.mirror ? 'rotateY(180deg)' : '' }}
                        ref={videoRef}
                    ></video>
                    {!!isActive && (
                        <div className="absolute bottom-0 right-0 p-4 z-50">
                            <div
                                onClick={() => {
                                    swapFacingMode()
                                    startCamera()
                                }}
                                className="bg-white shadow-md cursor-pointer text-gray-700 rounded-full p-2 h-10 w-10"
                            >
                                <ArrowPathIcon />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
