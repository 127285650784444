import React from 'react'
import { Link } from 'react-router-dom'
import { useApi } from '../api'
import { Loader } from '../components/Loader'
import { OrgChip } from '../components/OrgChip'
import { urls } from '../config'

export function Explore(): JSX.Element {
    const api = useApi()

    const { data: campaigns, isFetching } = api.useGetCampaignsForChampion()

    return (
        <div className="space-y-8">
            <div className="relative">
                <h1 className="text-4xl mt-2">Explore</h1>
                {/* <Link to="/events">Events</Link> */}
            </div>
            {isFetching || !campaigns ? (
                <Loader />
            ) : (
                <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-8">
                    {campaigns.map((campaign) => (
                        <Link key={campaign._id} to={`/campaign/${campaign._id}`}>
                            <div className="bg-white shadow-md rounded-3xl overflow-hidden">
                                <div
                                    className="pb-[60%] relative bg-cover bg-center"
                                    style={{
                                        backgroundImage: `url(${urls.media}/campaign/${campaign._id}.jpg?ts=${new Date(
                                            campaign.updatedAt
                                        ).valueOf()})`,
                                    }}
                                >
                                    <div className="absolute bottom-0 left-0 p-2">
                                        <OrgChip organisation={campaign.organisation} />
                                    </div>
                                </div>
                                <div className="p-4 h-32 flex items-center">
                                    <h2 className="text-xl font-medium line-clamp-3">{campaign.name}</h2>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    )
}
