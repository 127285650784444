import { useQuery } from 'react-query'
import { AxiosInstance } from 'axios'

export default (axios: AxiosInstance) => ({
    useGetOrgs: (
        params = {
            status: 'active',
            campaign: undefined,
        },
        { key = ['organisations'], staleTime = Infinity, refetchOnMount = true, ...rest } = {}
    ) =>
        useQuery(
            key,
            () =>
                axios
                    .get(`organisation`, {
                        params,
                    })
                    .then(({ data }: any) => data),
            { staleTime, refetchOnMount, ...rest }
        ),
})
