import React from 'react'
import { Organisation } from '../api/types'
import { urls } from '../config'
type Props = {
    organisation: Organisation
}
export const OrgChip = ({ organisation }: Props) => (
    <div className="flex items-center pr-8 bg-white p-1 rounded-full bottom-0 space-x-2 shadow-lg ">
        <div
            className="w-12 h-12 bg-cover bg-center bg-gray-200 rounded-full"
            style={{
                backgroundImage: `url(${urls.media}/organisation/${organisation._id}.jpg?ts=${new Date(
                    organisation.updatedAt
                ).valueOf()})`,
            }}
        ></div>
        <div className="">
            <div className="text-xs">Hosted By</div>
            <div className="text-xl font-medium">{organisation.name}</div>
        </div>
    </div>
)
