import React from 'react'
import clsx from 'clsx'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

type Props = {
    label?: string
    error?: string
    description?: string
    button?: JSX.Element
}

const inputClassName =
    'border h-12 rounded-lg px-4 focus:outline-none w-full focus:ring-2 focus:ring-blue-500 focus:border-transparent'
const labelClassName = 'block font-medium'
const descriptionClassName = 'text-gray-500 text-xs'
const wrapperClassName = 'space-y-2 w-full'

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & Props
export const Input = React.forwardRef<any, InputProps>(
    ({ id, label, button, description, className, error, ...props }, ref) => {
        return (
            <div className={wrapperClassName}>
                {!!label && (
                    <label className={labelClassName} htmlFor={id}>
                        {label}
                    </label>
                )}
                {!!description && <div className={descriptionClassName}>{description}</div>}
                <div className="relative w-full">
                    <input
                        {...props}
                        id={id}
                        ref={ref}
                        className={clsx(inputClassName, className, {
                            'pr-16': !!button,
                        })}
                    />
                    {!!button && <div className="absolute right-0 bottom-0">{button}</div>}
                </div>
                <div className="text-xs text-red-500">{error}</div>
            </div>
        )
    }
)

type DateInputProps = ReactDatePickerProps & Props
export const DateInput = React.forwardRef<any, DateInputProps>(({ id, description, label, error, ...props }, ref) => {
    return (
        <div className={wrapperClassName}>
            {!!label && (
                <label className={labelClassName} htmlFor={id}>
                    {label}
                </label>
            )}
            {!!description && <div className={descriptionClassName}>{description}</div>}
            <style>
                {`
                .react-datepicker-wrapper {
                    width: 100%;
                }
            `}
            </style>
            <DatePicker id={id} ref={ref} className={inputClassName} dateFormat="Pp" {...props} />
            <div className="text-xs text-red-500">{error}</div>
        </div>
    )
})

type TextAreaProps = React.InputHTMLAttributes<HTMLTextAreaElement> & Props
export const TextArea = React.forwardRef<any, TextAreaProps>(
    ({ id, description, textArea, label, error, ...props }: any, ref) => {
        return (
            <div className={wrapperClassName}>
                {!!label && (
                    <label className={labelClassName} htmlFor={id}>
                        {label}
                    </label>
                )}
                {!!description && <div className={descriptionClassName}>{description}</div>}
                <textarea {...props} id={id} ref={ref} className={clsx(inputClassName, 'py-3 min-h-[6rem]')}></textarea>
                <div className="text-xs text-red-500">{error}</div>
            </div>
        )
    }
)

type SelectProps = React.InputHTMLAttributes<HTMLSelectElement> & Props
export const Select = React.forwardRef<any, SelectProps>(
    ({ id, label, button, description, className, error, ...props }, ref) => {
        return (
            <div className={wrapperClassName}>
                {!!label && (
                    <label className={labelClassName} htmlFor={id}>
                        {label}
                    </label>
                )}
                {!!description && <div className={descriptionClassName}>{description}</div>}
                <div className="relative">
                    <select {...props} id={id} ref={ref} className={clsx(inputClassName, className)}>
                        {props.children}
                    </select>
                </div>
                <div className="text-xs text-red-500">{error}</div>
            </div>
        )
    }
)
