import React from 'react'
import { Event, Wallet } from '../../../api/types'
import { useState } from 'react'
import { SelectWallet } from './SelectWallet'
import { FetchWalletId } from './FetchWalletId'
import useApp from '../../../hooks/useApp'
import { CheckInUser } from './CheckInUser'
import { SignupUser } from './SignupUser'
import { Modal } from '../../../components/Modal'

type Props = {
    open: boolean
    onClose: (refresh?: boolean) => void
    event: Event
}

export function CheckInModal({ onClose, event }: Props): JSX.Element {
    const [appState] = useApp()

    const [state, setState] = useState<{
        index: number
        wallet?: Wallet
        account?: string
    }>(() => {
        if (event.status === 'approved' && appState.user?.wallets.celo?.account)
            return {
                index: 2,
                wallet: Wallet.Celo,
                account: appState.user.wallets.celo.account,
            }
        return { index: 0 }
    })

    return (
        <Modal open={true} onClose={onClose}>
            {/* Select Wallet */}
            {state.index === 0 && (
                <SelectWallet
                    event={event}
                    onWallet={(wallet) => {
                        setState({
                            ...state,
                            index: 1,
                            wallet,
                        })
                    }}
                />
            )}

            {/* Fetch Wallet Id */}
            {state.index === 1 && (
                <FetchWalletId
                    event={event}
                    wallet={state.wallet!}
                    onAccount={(account) => {
                        setState({
                            ...state,
                            index: 2,
                            account,
                        })
                    }}
                />
            )}

            {/* Check-In */}
            {state.index === 2 && (
                <CheckInUser
                    onDone={() => onClose(true)}
                    wallet={state.wallet!}
                    account={state.account!}
                    onNotFound={() => {
                        setState({
                            ...state,
                            index: 3,
                        })
                    }}
                    event={event}
                />
            )}

            {/* Sign Up User */}
            {state.index === 3 && (
                <SignupUser
                    onUser={() => {
                        setState({
                            ...state,
                            index: 2,
                        })
                    }}
                    wallet={state.wallet!}
                    account={state.account!}
                    event={event}
                />
            )}
        </Modal>
    )
}
