import React from 'react'
import { Dialog } from '@headlessui/react'
import { Event, Wallet } from '../../../api/types'
import { QrCamera } from '../../../components/QrCamera'
import { useState } from 'react'
import { Button } from '../../../components/Button'
import { Input } from '../../../components/Input'
import { useForm } from 'react-hook-form'
import { ArrowLeftIcon, QrCodeIcon } from '@heroicons/react/24/outline'

type Props = {
    onAccount: (id: string) => void
    wallet: Wallet
    event: Event
}

export function FetchWalletId({ event, onAccount }: Props): JSX.Element {
    const [scan, setScan] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const walletAddressRegex = new RegExp(/^0x[a-fA-F0-9]{40}$/)

    return (
        <>
            <div>
                <Dialog.Title className="text-2xl">
                    {event.status === 'approved' ? 'Self Check-In' : 'Check-In'}
                </Dialog.Title>
                <div className="text-xs text-gray-500">Check-In volunteers with a supported wallets.</div>
            </div>
            {scan ? (
                <div className="space-y-2">
                    <div
                        onClick={() => {
                            setScan(false)
                        }}
                        className="flex cursor-pointer text-gray-500 hover:text-gray-700 space-x-2 items-center"
                    >
                        <ArrowLeftIcon className="h-4" />
                        <div>Back</div>
                    </div>
                    <div className="w-full aspect-square rounded-3xl overflow-hidden">
                        <QrCamera
                            title="Scan A Wallet Qr Code"
                            onScan={(address) => {
                                if (walletAddressRegex.test(address)) onAccount(address)
                            }}
                        />
                    </div>
                </div>
            ) : (
                <form
                    className="w-full"
                    onSubmit={handleSubmit(({ account }) => {
                        onAccount(account)
                    })}
                >
                    <div className="space-y-4">
                        <Input
                            className="font-mono"
                            error={errors.account && 'Input a valid CELO wallet address.'}
                            label="Wallet Address"
                            description="Check-In with a CELO wallet address."
                            placeholder="0x..."
                            button={
                                <Button
                                    onClick={() => {
                                        setScan(true)
                                    }}
                                    square
                                >
                                    <QrCodeIcon className="h-6" />
                                </Button>
                            }
                            {...register('account', {
                                required: true,
                                pattern: walletAddressRegex,
                            })}
                        />
                        <Button type="submit">Continue</Button>
                    </div>
                </form>
            )}
        </>
    )
}
