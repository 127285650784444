import React from 'react'
import { Loader } from '../components/Loader'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

export function Signout(): JSX.Element {
    const { logout } = useAuth0()

    useEffect(() => {
        logout({
            logoutParams: { returnTo: 'https://vollar.org' },
        })
    }, [])
    return <Loader />
}
