import React from 'react'
import { Event } from '../../../api/types'
import { useApi } from '../../../api'
import { Loader } from '../../../components/Loader'
import { useEffect } from 'react'
import { Button } from '../../../components/Button'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { urls } from '../../../config'

type Props = {
    // onUser: (user?: User) => void
    wallet: string
    account: string
    event: Event
    onDone: () => void
    onNotFound: () => void
}

export function CheckInUser({ event, wallet, account, onDone, onNotFound }: Props): JSX.Element {
    const api = useApi()
    const { mutate: checkIn, data, error } = api.useEventCheckIn(event._id)

    useEffect(() => {
        checkIn({ wallet, account })
    }, [])

    useEffect(() => {
        if (error) {
            if (error.statusCode === 404) {
                onNotFound()
            }
        }
    }, [error])

    if (error) {
        if (error.statusCode === 404) {
            return <></>
        } else {
            switch (error.message) {
                case 'AlreadyCheckedIn':
                    return (
                        <div className="space-y-4 flex text-center flex-col justify-center">
                            <CheckCircleIcon className="h-16 text-green-500" />
                            <div className="text-2xl">Already Checked In</div>
                            <Button onClick={onDone}>Close</Button>
                        </div>
                    )
                case 'WalletAlreadyRegistered':
                    return (
                        <div className="space-y-4 flex text-center flex-col justify-center">
                            <XCircleIcon className="h-16 text-red-500" />
                            <div className="text-2xl">A User Exists With This Wallet</div>
                            <Button onClick={onDone}>Close</Button>
                        </div>
                    )
            }
        }
    }

    if (data) {
        return (
            <>
                <div className="space-y-4 flex text-center flex-col justify-center">
                    <div className="text-2xl">Checked In</div>
                </div>
                <div className="w-full space-y-4 flex flex-col justify-center items-center">
                    <div
                        className="relative h-32 w-32 rounded-full bg-cover bg-center"
                        style={{
                            backgroundImage: `url(${urls.media}/user/${data.user._id}.jpg?ts=${new Date(
                                data.user.updatedAt
                            ).valueOf()})`,
                        }}
                    >
                        <div className="p-1 rounded-full bg-white absolute bottom-0 left-0 -ml-3 -mb-3">
                            <CheckCircleIcon className="h-12 text-green-500" />
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="text-xl">{data.user.firstName}</div>
                        <div>{data.user.lastName}</div>
                    </div>
                </div>
                <Button onClick={onDone}>Close</Button>
            </>
        )
    } else {
        return <Loader />
    }
}
