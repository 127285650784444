import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from 'react-query'
import { AxiosInstance } from 'axios'
import { CreateEventPayload, Error, Event, EventWithCampaign, User } from './types'

type CheckInPayload = {
    wallet: string
    account: string
}

type RemoveVolunteerPayload = {
    user: string
}

type CheckInResponse = {
    user: User
    event: Event
}

export default (axios: AxiosInstance) => ({
    useCreateEvent: (options: UseMutationOptions<Event, unknown, CreateEventPayload, unknown> | undefined) =>
        useMutation<Event, Error, CreateEventPayload, unknown>(
            (data) => axios.post(`/event`, data).then(({ data }) => data),
            options
        ),
    useGetEvent: (
        id: string,
        {
            key = ['event', id],
            ...options
        }: UseQueryOptions<any, any, Event, any> & {
            key?: unknown
        } = {}
    ) =>
        useQuery<any, any, Event, any>(key, () => axios.get(`/event/${id}`).then(({ data }: any) => data), {
            staleTime: Infinity,
            refetchOnMount: 'always',
            ...options,
        }),

    useEventCheckIn: (event: string, options?: UseMutationOptions<any, any, any, any>) =>
        useMutation<CheckInResponse, Error, CheckInPayload, any>(
            (data) => axios.post(`/event/${event}/checkIn`, data).then(({ data }) => data),
            options
        ),
    useEventRemoveVolunteer: (event: string, options?: UseMutationOptions<any, any, any, any>) =>
        useMutation<Event, Error, RemoveVolunteerPayload, any>(
            (data) => axios.post(`/event/${event}/removeVolunteer`, data).then(({ data }) => data),
            options
        ),
    useCloseEvent: (event: string, options?: UseMutationOptions<any, any, any, any>) =>
        useMutation<Event, Error, any, any>(
            () => axios.post(`/event/${event}/close`).then(({ data }) => data),
            options
        ),
    useGetEvents: ({
        key = ['events'],
        ...options
    }: UseQueryOptions<any, any, EventWithCampaign[], any> & {
        key?: unknown
    } = {}) =>
        useQuery<any, any, EventWithCampaign[], any>(key, () => axios.get(`/event`).then(({ data }: any) => data), {
            staleTime: Infinity,
            refetchOnMount: true,
            ...options,
        }),
})
