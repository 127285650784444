import React from 'react'
import { useParams } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { useApi } from '../../api'
import { Loader } from '../../components/Loader'
import { Button } from '../../components/Button'
import { Status } from '../../components/Status'
import { CheckInModal } from './checkin/CheckInModal'
import { TrashIcon } from '@heroicons/react/24/outline'
import { Modal } from '../../components/Modal'
import { Volunteer } from '../../api/types'
import clsx from 'clsx'
import { urls } from '../../config'

export function Event(): JSX.Element {
    let { id } = useParams()
    const api = useApi()
    const [open, setOpen] = useState(false)
    const [showRemoveVolunteer, setShowRemoveVolunteer] = useState<Volunteer | null>(null)
    const [showCloseEvent, setShowCloseEvent] = useState<boolean>(false)
    // const [refetch, setRefetch] = useState(false)
    const {
        data: event,
        isLoading,
        refetch,
    } = api.useGetEvent(id ?? '', {
        // refetchInterval: refetchInterval,
        // refetchOnMount: true,
    })
    const { mutateAsync: removeVolunteer } = api.useEventRemoveVolunteer(id ?? '')
    const { mutateAsync: closeEvent } = api.useCloseEvent(id ?? '')

    const getStatusMessage = useCallback(() => {
        if (!event) return ''

        switch (event.status) {
            case 'pending':
                return `The host hasn't approved your event yet.`
            case 'approved':
                return 'Your event has been approved by the host.'
            case 'rejected':
                return 'Your event was rejected by the host.'
            case 'cancelled':
                return 'You cancelled this event.'
            case 'open':
                return 'Your event is open for check-ins.'
            case 'closed':
                return 'Your event is closed for check-ins.'
            default:
                return ''
        }
    }, [event])

    // useEffect(() => {
    //     if (event?.status !== 'approved') {
    //         setRefetch(true)
    //     } else {
    //         setRefetch(false)
    //     }
    // }, [event])

    const volunteers = Object.values(event?.volunteers ?? {})

    return (
        <div className="space-y-8 pb-32">
            {isLoading || !event ? (
                <Loader />
            ) : (
                <>
                    <div className="space-y-2">
                        <Status status={event.status} />
                        <h1 className="text-4xl mt-2">{event.name}</h1>
                        <div className="text-xs text-gray-500">{getStatusMessage()}</div>
                        <div className="grid gap-1 grid-cols-1 md:grid-cols-3">
                            <div className="text-gray-500 flex space-x-2">
                                <div className="font-medium">Capacity:</div>
                                <div>{event.capacity} Volunteers</div>
                            </div>
                            <div className="text-gray-500 flex space-x-2">
                                <div className="font-medium">Duration:</div>
                                <div>{event.duration} Hours</div>
                            </div>
                            <div className="text-gray-500 flex space-x-2">
                                <div className="font-medium">Reward:</div>
                                <div>
                                    {event.funding.reward} {event.funding.currency}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-4">
                        <div className="border-l-4 pl-4 py-2 border-teal-400">{event.description}</div>
                    </div>
                    {event.status !== 'pending' && (
                        <>
                            <hr />
                            <div className="space-y-6">
                                <h2 className="text-2xl">Volunteers</h2>
                                {volunteers.length === 0 && <div className="text-gray-500">No volunteers yet.</div>}
                                <div className="">
                                    {Object.values(event.volunteers).map((volunteer) => (
                                        <div
                                            key={volunteer._id}
                                            className={clsx('flex p-2 pr-4 rounded-2xl items-center justify-between', {
                                                'hover:bg-gray-100 ': event.status === 'open',
                                            })}
                                        >
                                            <div className="flex items-center space-x-2">
                                                <div
                                                    style={{
                                                        backgroundImage: `url(${urls.media}/user/${volunteer._id}.jpg)`,
                                                    }}
                                                    className="w-10 h-10 rounded-full bg-gray-200 bg-center bg-cover"
                                                ></div>
                                                <div className="text-lg">{volunteer.name}</div>
                                            </div>
                                            {volunteer._id !== event.champion && event.status === 'open' && (
                                                <div>
                                                    <TrashIcon
                                                        onClick={() => {
                                                            setShowRemoveVolunteer(volunteer)
                                                        }}
                                                        className="h-6 cursor-pointer text-gray-500 hover:text-red-700 hover:scale-110 transition-transform"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                    {(event.status === 'approved' || event.status === 'open') && (
                        <div className="fixed bg-white bg-whites left-0 bottom-0 border-t w-full shadow-2xl">
                            <div className="max-w-4xl p-4 m-auto">
                                {event.status === 'approved' ? (
                                    <div className="gap-4 flex w-full justify-center items-center">
                                        <div>Check-in to open the event.</div>
                                        <Button onClick={() => setOpen(true)} color="blue" inline>
                                            Check In
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="gap-4 flex max-w-4xl m-auto w-full justify-between items-center">
                                        <Button onClick={() => setShowCloseEvent(true)} outline color="red" inline>
                                            Close Event
                                        </Button>
                                        <Button onClick={() => setOpen(true)} inline>
                                            Check In Volunteer
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {open && (
                        <CheckInModal
                            event={event}
                            open={open}
                            onClose={(refresh) => {
                                if (refresh) {
                                    refetch()
                                }
                                setOpen(false)
                            }}
                        />
                    )}

                    {/* Remove Volunteer */}
                    {event.status === 'open' && (
                        <Modal open={!!showRemoveVolunteer} onClose={() => setShowRemoveVolunteer(null)}>
                            <div>
                                Do you want to remove <b>{showRemoveVolunteer?.name}</b>
                            </div>
                            <div className="flex gap-4">
                                <Button
                                    onClick={() => {
                                        setShowRemoveVolunteer(null)
                                    }}
                                    outline
                                    color="red"
                                >
                                    Close
                                </Button>
                                <Button
                                    onClick={async () => {
                                        const id = showRemoveVolunteer?._id ?? ''
                                        setShowRemoveVolunteer(null)
                                        await removeVolunteer({
                                            user: id,
                                        })
                                        refetch()
                                    }}
                                    color="red"
                                >
                                    Remove
                                </Button>
                            </div>
                        </Modal>
                    )}

                    {/* Close Event */}
                    {event.status === 'open' && (
                        <Modal open={showCloseEvent} onClose={() => setShowRemoveVolunteer(null)}>
                            <div>Do you want to close this event?</div>
                            <div className="flex gap-4">
                                <Button
                                    onClick={() => {
                                        setShowCloseEvent(false)
                                    }}
                                    outline
                                    color="red"
                                >
                                    Close
                                </Button>
                                <Button
                                    onClick={async () => {
                                        await closeEvent({})
                                        refetch()
                                    }}
                                >
                                    Continue
                                </Button>
                            </div>
                        </Modal>
                    )}
                </>
            )}
        </div>
    )
}
