import { createContext } from 'react'
import { User } from './api/types'

export type AppState = {
    user?: User
    requireSignup?: boolean
    userLoaded?: boolean
}
export const AppContext = createContext<{
    appState: AppState
    setAppState: (state: AppState) => void
}>({
    appState: {},
    setAppState: () => {},
})

export const appStateKey = 'AppContext'
