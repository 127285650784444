import { useAuth0 } from '@auth0/auth0-react'
import organisation from './organisation'
import user from './user'
import axios from 'axios'
import { auth0 } from '../config'
import campaign from './campaign'
import event from './event'
import { urls } from '../config'

export function useApi() {
    const { getAccessTokenSilently: getToken, loginWithRedirect } = useAuth0()
    const axiosBase = axios.create({
        baseURL: urls.api,
    })

    axiosBase.interceptors.request.use(async (config) => {
        console.log('Get Token')
        await getToken({
            authorizationParams: {
                audience: auth0.audience,
            },
        })
            .then((accessToken) => {
                console.log('Token', accessToken)
                config.headers.set('Authorization', `Bearer ${accessToken}`)
            })
            .catch((error) => {
                console.log('Error', error)
            })

        return config
    })

    axiosBase.interceptors.response.use(
        (res) => res,
        async (err) => {
            console.log('Request Error', err)
            if (err.response.status === 401) {
                loginWithRedirect()
            }
            throw err.response.data
        }
    )

    return {
        ...organisation(axiosBase),
        ...user(axiosBase),
        ...campaign(axiosBase),
        ...event(axiosBase),
    }
}
