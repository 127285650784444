import clsx from 'clsx'
import { Link } from 'react-router-dom'

type Props = {
    children: any
    to?: string
    square?: boolean
    onClick?: () => void
    inline?: boolean
    outline?: boolean
    color?: 'blue' | 'red' | 'yellow'
}

export const Button = ({
    children,
    outline,
    inline,
    square,
    to,
    className,
    color,
    ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & Props) => {
    const button = (
        <button
            {...props}
            className={clsx(
                'text-center flex justify-center items-center text-white leading-5 bg-teal-500 hover:bg-teal-600 active:bg-teal-700 active:scale-95 transition-all text-base md:text-lg px-4 rounded-lg font-medium h-12',
                {
                    'inline-block': inline,
                    'w-full': !inline,
                    '!w-12 !p-0': square,
                    'border-2 border-teal-500 text-teal-500 hover:!bg-teal-100 active:!bg-teal-200 bg-transparent':
                        outline,

                    '!bg-red-500 hover:!bg-red-600 active:!bg-red-700': color === 'red' && !outline,
                    '!bg-blue-500 hover:!bg-blue-600 active:!bg-blue-700': color === 'blue' && !outline,
                    '!bg-yellow-500 hover:!bg-yellow-600 active:!bg-yellow-700': color === 'yellow' && !outline,

                    '!border-red-500 !text-red-500 hover:!bg-red-100 active:!bg-red-200': color === 'red' && outline,
                    '!border-blue-500 !text-rblueed-500 hover:!bg-blue-100 active:!bg-blue-200':
                        color === 'blue' && outline,
                    '!border-yellow-500 !text-yellow-500 hover:!bg-yellow-100 active:!bg-yellow-200':
                        color === 'yellow' && outline,
                },
                className
            )}
        >
            {children}
        </button>
    )

    if (to)
        return (
            <Link to={to} className="block">
                {button}
            </Link>
        )
    else return button
}
